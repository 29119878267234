<template>
    <div :class="{ loader: true, fadeout: !isLoading }">
        <div class="loading-spinner " :class="{ fadeout: !isLoading }"
             style="background-color:rgba(0,0,0,0); width: 100vw; height: 100vh">
        </div>
    </div>
</template>

<script>
export default {
    name: "loadingScreen",
    props: ["isLoading"]
};
</script>

<style>
.loading-spinner {
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%) translateZ(0);
    top: 50%;
    width: 90px;
    height: 90px;
    z-index: 5000;
    display: flex;
    align-items: center;
    opacity: 1;
    justify-content: center;
    background: url("/img/logoSpinner.png") center no-repeat;
    background-size: 6%;
    animation: spinning 1.5s infinite ease-in-out;
}

.loading-spinner:after {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    /*background: url("/img/logoSpinner.png") center no-repeat;*/
    /*background-size: contain;*/
    border-radius: 100%;
    margin: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 0 0 #1BC77B;
    transition: all 1s linear;
    animation: lds-eclipse 1s linear infinite;
}

@keyframes spinning {
    0% {
        transform: translate(-50%, -50%) scale(1) translateZ(0);
    }
    50% {
        transform: translate(-50%, -50%) scale(1.1) translateZ(0);
    }
    100% {
        transform: translate(-50%, -50%) scale(1) translateZ(0);
    }
}

@keyframes lds-eclipse {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5000;
    background: #ffffff;
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader .spinner-border {
    width: 3rem;
    height: 3rem;
    color: #ffffff;
}

.fadeout {
    animation: fadeout 1.7s forwards;
}

@keyframes fadeout {
    to {
        opacity: 0;
        visibility: hidden;
    }
}
</style>

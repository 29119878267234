<template>
   <div class="container">
      <loadingScreen :isLoading="isLoading"/>
      <form @submit.prevent="createUser">
         <div class="row justify-content-md-center">
            <div class="login-screen">
               <div class="login-box">
                  <a href="#" class="login-logo">
                     <img src="@/assets/img/logo.png" alt="SmartQ Admin Dashboard">
                  </a>
                  <h5 id="titulo">
                     {{ t('GENERAL.CREATE_YOUR_ACCOUNT') }}.
                  </h5>
                  <div class="form-group">
                     <input data-cy="login_creat_name" type="text" id="name" v-model="name" name="name"
                            class="form-control" @keyup="inputName(name)" placeholder="Nome completo"/>
                     <div class="validation" v-if="isNameInvalid">{{ t(msgError) }}</div>
                  </div>
                  <div class="form-group">
                     <input data-cy="login_creat_email" type="text" id="email" v-model="email" name="email"
                            class="form-control" @keyup="clearMail()" placeholder="Endereço de e-mail"/>
                     <div class="validation" id="invalidEmail" style="display: none">{{ msgEmail }}</div>
                  </div>
                  <div class="form-group">
                     <div class="input-group">
                        <input data-cy="login_creat_password" type="password" id="password" v-model="password"
                               name="password" class="form-control" placeholder="Senha"
                               @keyup="clearPassword()"/>
                        <input data-cy="login_creat_password_confirm" type="password" id="passwordConfirm"
                               v-model="passwordConfirm" name="passwordConfirm" class="form-control"
                               placeholder="Confirmar senha"
                               @keyup="clearPassword()"/>
                     </div>
                     <div class="validation" id="invalidPassword" style="display: none">{{ msgPassword }}</div>
                     <small id="passwordHelpInline" class="text-muted">
                        {{ t('GENERAL.MSG.MAX_MIN_PASSWORD') }}
                     </small>
                  </div>
                  <button data-cy="login_creat_button_confirm" style="width: 100%;" type="submit"
                          class="btn btn-primary">
                     {{ t('GENERAL.CREATE_ACCOUNT') }}
                  </button>
                  <google-singup></google-singup>
                  <hr>
                  <div class="m-0">
                     <span class="additional-link">{{ t('GENERAL.ALREADY_REGISTRED') }}? </span>
                     <router-link class="btn btn-dark" to="/login">
                        {{ t('GENERAL.LOGIN') }}
                     </router-link>
                  </div>
               </div>
            </div>
         </div>
      </form>
   </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import User from '@/services/user';
import avatar from "@/../public/avatar.png";
import {useToast} from "vue-toastification";
import Validate from '@/components/validate.vue';
import googleSingup from './googleSingupComponent.vue';
import LoadingScreen from '@/components/layouts/loadScreenComponent.vue';

export default {
   name: 'SingUpComponent',
   mixins: [Validate],
   setup() {
      const {t} = useI18n();
      const toast = useToast();
      return {t, toast}
   },
   components: {
      googleSingup,
      LoadingScreen,
   },

   data() {
      return {
         user: {},
         name: null,
         email: null,
         msgName: '',
         invalid: [],
         password: null,
         isLoading: false,
         passwordConfirm: null,
      }
   },

   created() {
      document.title = this.t('APP.TITLE.SYSTEM') + this.t('APP.TITLE.SINGUP');
   },

   methods: {
      clearMail()
      {
         if (this.email){
            this.email = this.email.trim();
            this.email = this.email.toLowerCase();
         }
         this.inputEmail(this.email)
      },
      clearPassword(){
         if (this.password) {
            this.password = this.password.trim();
         }
         if (this.passwordConfirm) {
            this.passwordConfirm = this.passwordConfirm.trim();
         }
         this.inputPassword(this.password, this.passwordConfirm);
      },
      createUser: function () {
         if (this.checkForm()) {
            this.isLoading = true;
            this.user = {
               name: this.name,
               email: this.email,
               password: this.password,
               avatar: avatar,
               scope: ""
            }

            User.register(this.user).then(() => {
               setTimeout(() => this.$router.push({
                  name: 'Login'
               }), 500);

               this.isLoading = false;
               this.toast.success(this.t('GENERAL.MSG.REGISTER_SUCCESS'));
            }).catch(error => {
               this.isLoading = false;
               this.errorMsg(error);
            });
         }
      },

      checkForm: function () {
         this.invalid = [];

         this.nameValidate(this.name);
         this.emailValidate(this.email);
         this.passwordValidade(this.password, this.passwordConfirm);

         if (this.invalid.length && this.invalid.length > 0) {
            return false;
         }

         return true;
      },
   }
}
</script>
<style lang="css">
#titulo {
   font-weight: bold
}

input:invalid {
   border: 1px solid red !important;
}

.fade-enter-active,
.fade-leave-active {
   transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
   opacity: 0;
}
</style>
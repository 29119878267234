<template>
   <div>
      <loadingScreen :isLoading="isLoading"/>
      <div class="mb-4 mt-1">
         <button data-cy="login_with_google" class="btn btn-secondary" style="width: 100%;" @click.stop.prevent="singIn"
                 :disabled="!googleOauth.isInit">
            <i class="fab fa-google fa-2x mr-2"></i>{{ t('GENERAL.MSG.CONTINUE_WITH_GOOGLE') }}
         </button>
      </div>
   </div>
</template>

<script>

import {inject} from "vue";
import {useI18n} from "vue-i18n";

import User from '@/services/user';
import LoadingScreen from '@/components/layouts/loadScreenComponent.vue';

export default {
   name: 'googleSingup',
   setup() {
      const {t} = useI18n();
      const googleOauth = inject("Vue3GoogleOauth");

      return {
         t,
         googleOauth,
      }
   },

   data() {
      return {
         isLoading: false,
      }
   },

   components: {
      LoadingScreen,
   },

   methods: {
      async singIn() {
         this.isLoading = true;
         try {
            const user = await this.$gAuth.signIn();

            if (!user)
               return null;

            this.onSignInGoogle(user.getAuthResponse(), user.getBasicProfile().getImageUrl())

         } catch (error) {
            this.isLoading = false;
            this.$emit(error, error);
            return null;
         }
      },

      onSignInGoogle(user, avatar) {
         user.avatar = avatar;
         User.google(user).then(resp => {
            this.$store.dispatch('ActionDoLogin', resp.data);
            this.isLoading = false;
            this.$router.push({name: 'Home'});
         });
      },
   },
};
</script>